.auth-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    width: 100%;
    padding: 80px 15px;
}

.auth-container {
    width: 100%;
    max-width: 400px;
    background-color: rgba(22, 22, 22, 0.6);
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid var(--border);
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.15);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
}

.recaptcha-container {
    margin-bottom: 15px;
  }

.auth-tabs {
    display: flex;
    border-bottom: 1px solid var(--border);
}



.auth-error {
    background-color: #fff0f0;
    color: #d93025;
    padding: 14px 16px;
    border-radius: 6px;
    margin-bottom: 20px;
    font-size: 14px;
    text-align: center;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
    border-left: 4px solid #d93025;
    animation: fadeIn 0.3s ease-in-out;
    font-weight: 500;
  }
  
  .auth-success {
    background-color: #edf7ed;
    color: #0f9d58;
    padding: 14px 16px;
    border-radius: 6px;
    margin-bottom: 20px;
    font-size: 14px;
    text-align: center;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
    border-left: 4px solid #0f9d58;
    animation: fadeIn 0.3s ease-in-out;
    font-weight: 500;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .btn-auth:disabled,
  .telegram-auth-btn:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
  
  .btn-auth {
    position: relative;
  }
  
  .btn-auth:disabled:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 20px;
    height: 20px;
    border: 2px solid rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    border-top-color: #fff;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    to {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
  
  .telegram-auth-btn {
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 12px;
    background-color: #0088cc;
    border: none;
    border-radius: 4px;
    color: white;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .telegram-auth-btn:hover {
    background-color: #0077b3;
  }
  
  .terms-link {
    color: #0088cc;
    text-decoration: none;
  }
  
  .terms-link:hover {
    text-decoration: underline;
  }

.auth-tab {
    flex: 1;
    background: none;
    border: none;
    padding: 1.2rem;
    color: var(--text-muted);
    font-size: 1rem;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    font-family: 'Inter', sans-serif;
    text-transform: lowercase;
}

.auth-tab:hover {
    color: var(--text);
}

.auth-tab.active {
    color: var(--primary);
    background-color: rgba(93, 63, 211, 0.05);
}

.auth-content {
    padding: 2rem;
}

.auth-form {
    display: none;
}

.auth-form.active {
    display: block;
}

.auth-form h2 {
    font-size: 1.8rem;
    margin-bottom: 0.5rem;
    text-align: center;
}

.auth-desc {
    color: var(--text-muted);
    margin-bottom: 2rem;
    text-align: center;
}

.form-group {
    margin-bottom: 1.5rem;
}

.form-group label {
    display: block;
    margin-bottom: 0.5rem;
    color: var(--text-light);
    font-size: 0.95rem;
}

.form-group input {
    width: 100%;
    padding: 0.8rem 1rem;
    background-color: rgba(15, 15, 15, 0.5);
    border: 1px solid var(--border);
    border-radius: 6px;
    color: var(--text);
    font-family: 'Inter', sans-serif;
    font-size: 0.95rem;
    transition: all 0.2s ease;
}

.form-group input:focus {
    outline: none;
    border-color: var(--primary);
}

.form-group input::placeholder {
    color: var(--text-muted);
    opacity: 0.7;
}

.form-options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
    font-size: 0.9rem;
}

.checkbox-container {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: var(--text-light);
}

.checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    height: 18px;
    width: 18px;
    margin-right: 8px;
    background-color: rgba(15, 15, 15, 0.5);
    border: 1px solid var(--border);
    border-radius: 3px;
    display: inline-block;
    position: relative;
    top: 1px;
}

.checkbox-container:hover .checkmark {
    border-color: var(--primary);
}

.checkbox-container input:checked ~ .checkmark {
    background-color: var(--primary);
    border-color: var(--primary);
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.checkbox-container input:checked ~ .checkmark:after {
    display: block;
}

.checkbox-container .checkmark:after {
    left: 6px;
    top: 2px;
    width: 4px;
    height: 9px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

.forgot-password {
    color: var(--primary);
    text-decoration: none;
    transition: all 0.2s ease;
}

.forgot-password:hover {
    text-decoration: underline;
}

.btn-auth {
    width: 100%;
    padding: 0.85rem;
    margin-bottom: 1.5rem;
    background-color: rgba(93, 63, 211, 0.2);
    border: 1px solid var(--primary);
    border-radius: 8px;
    color: var(--text);
    font-weight: 500;
    font-size: 0.95rem;
    cursor: pointer;
    transition: all 0.2s ease;
    font-family: 'Inter', sans-serif;
    text-transform: lowercase;
    letter-spacing: 0.5px;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
}

.btn-auth:hover {
    background-color: rgba(93, 63, 211, 0.3);
    transform: translateY(-1px);
    box-shadow: 0 5px 15px rgba(93, 63, 211, 0.15);
}

.auth-separator {
    display: flex;
    align-items: center;
    text-align: center;
    margin-bottom: 1.5rem;
}

.auth-separator span {
    color: var(--text-muted);
    padding: 0 1rem;
    font-size: 0.9rem;
}

.auth-separator::before,
.auth-separator::after {
    content: '';
    flex: 1;
    border-bottom: 1px solid var(--border);
}

.telegram-auth-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0.85rem;
    background-color: rgba(42, 171, 238, 0.2);
    color: var(--text);
    text-decoration: none;
    border-radius: 8px;
    transition: all 0.2s ease;
    font-weight: 500;
    font-size: 0.95rem;
    border: 1px solid #2AABEE;
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
}

.telegram-auth-btn:hover {
    background-color: rgba(42, 171, 238, 0.3);
    transform: translateY(-1px);
    box-shadow: 0 5px 15px rgba(42, 171, 238, 0.15);
}

@media (max-width: 576px) {
    .auth-content {
        padding: 1.5rem;
    }
    
    .form-options {
        flex-direction: column;
        align-items: flex-start;
        gap: 1rem;
    }
}