
:root {
  --bg-main: rgba(20, 20, 20, 0.5);
  --bg-card: #1a1a20;
  --bg-sidebar: rgba(15, 15, 15, 0.8);
  --primary: #6c5ce7;
  --primary-hover: #5649c0;
  --danger: #ff5252;
  --warning: #ffcc00;
  --border: #272730;
  --border-light: #2d2d3a;
  --text: #ffffff;
  --text-muted: #6b6b80;
  --shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}


.dashboard-wrapper {
  padding: 8rem 0 4rem;
  min-height: calc(100vh - 80px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboard-container {
  display: flex;
  width: 1000px;
  min-height: 600px;
  background-color: var(--bg-main);
  border-radius: 16px;
  border: 1px solid var(--border);
  overflow: hidden;
  box-shadow: var(--shadow);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
}

.dashboard-content {
  flex: 1;
  padding: 2rem;
  overflow-y: auto;
  min-width: 0;
}

.content-section {
  margin-bottom: 2rem;
  min-height: 450px;
}

.section-title {
  margin-bottom: 1.5rem;
  font-size: 1.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid var(--border);
  color: var(--text);
}


.dashboard-sidebar {
  width: 260px;
  background-color: var(--bg-sidebar);
  border-right: 1px solid var(--border);
  display: flex;
  flex-direction: column;
}

.user-info {
  padding: 2rem;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--border);
}

.avatar {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: linear-gradient(135deg, var(--primary), #7560e0);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1rem;
}

.avatar span {
  color: white;
  font-size: 1.5rem;
  font-weight: 600;
}

.user-details h3 {
  font-size: 1.1rem;
  margin-bottom: 0;
  color: var(--text);
}

.navigation-menu {
  flex: 1;
  padding: 1.5rem 0;
}

.nav-item {
  display: flex;
  align-items: center;
  padding: 0.8rem 2rem;
  font-size: 1rem;
  color: var(--text);
  background: none;
  border: none;
  cursor: pointer;
  width: 100%;
  text-align: left;
  transition: all 0.2s ease;
  border-left: 3px solid transparent;
}

.nav-item:hover {
  background-color: rgba(108, 92, 231, 0.1);
  color: var(--primary);
}

.nav-item.active {
  background-color: rgba(108, 92, 231, 0.15);
  color: var(--primary);
  border-left-color: var(--primary);
}

.sidebar-footer {
  padding: 1.5rem 2rem;
  border-top: 1px solid var(--border);
}

.logout-button {
  background: none;
  border: 1px solid var(--danger);
  color: var(--danger);
  padding: 0.6rem 1rem;
  border-radius: 4px;
  width: 100%;
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 0.9rem;
}

.logout-button:hover {
  background-color: rgba(255, 82, 82, 0.1);
}


.profile-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1.5rem;
}

.profile-card {
  background-color: var(--bg-card);
  border-radius: 8px;
  padding: 1.5rem;
  border: 1px solid var(--border);
}

.profile-card h3 {
  font-size: 1.1rem;
  margin-bottom: 1.2rem;
  color: var(--primary);
}

.info-group {
  margin-bottom: 1rem;
}

.info-group label {
  display: block;
  font-size: 0.9rem;
  color: var(--text-muted);
  margin-bottom: 0.3rem;
}

.info-group p {
  font-size: 1rem;
  margin: 0;
  color: var(--text);
}


.subscription-card {
  background-color: var(--bg-card);
  border-radius: 8px;
  padding: 0;
  border: 1px solid var(--border);
  margin-bottom: 1.5rem;
  overflow: hidden;
}

.subscription-header {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #222230;
  padding: 1.2rem;
  border-bottom: 1px solid var(--border-light);
}

.plan-price {
  font-size: 1.6rem;
  color: var(--primary);
  font-weight: 700;
  letter-spacing: 0.5px;
}

.subscription-details {
  padding: 0;
}

.expire-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid var(--border);
}

.expire-label {
  font-size: 0.95rem;
  color: var(--text);
  font-weight: 500;
}

.days-left {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  color: var(--text);
  font-size: 0.95rem;
}

.days-left span:first-child {
  color: var(--text-muted);
}

.days-left span:last-child {
  color: white;
  font-weight: 700;
  font-size: 1.1rem;
  padding: 5px 12px;
  background-color: var(--primary);
  border-radius: 4px;
  display: inline-block;
  min-width: 30px;
  text-align: center;
}


.status-active {
  background-color: var(--primary);
  color: white;
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.5px;
  display: inline-block;
}

.status-frozen {
  background-color: var(--warning);
  color: #000;
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.5px;
  display: inline-block;
}

.status-danger {
  background-color: var(--danger);
  color: white;
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0.5px;
  display: inline-block;
}

.status-warning {
  color: var(--warning);
}


.dashboard-btn, .btn-full, .payment-btn, .action-btn {
  width: 100%;
  margin-top: 0.5rem;
  background-color: var(--primary);
  color: white;
  border: none;
  padding: 12px;
  font-weight: 500;
  font-size: 15px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease;
  text-transform: lowercase;
}

.dashboard-btn:hover, .btn-full:hover, .payment-btn:hover, .action-btn:hover {
  background-color: var(--primary-hover);
}

.freeze-btn, .extend-btn {
  background-color: var(--primary);
}

.unfreeze-btn {
  background-color: var(--warning);
  color: #000;
}

.unfreeze-btn:hover {
  background-color: #e6b800;
}


.settings-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(350px, 1fr));
  gap: 1.5rem;
}

.settings-card {
  background-color: var(--bg-card);
  border-radius: 8px;
  padding: 1.5rem;
  border: 1px solid var(--border);
}

.settings-card h3 {
  font-size: 1.1rem;
  margin-bottom: 1.2rem;
  color: var(--primary);
}

.settings-form .form-group {
  margin-bottom: 1.2rem;
}

.settings-form label {
  display: block;
  font-size: 0.9rem;
  color: var(--text-muted);
  margin-bottom: 0.5rem;
}

.settings-form input {
  width: 100%;
  padding: 0.8rem 1rem;
  background-color: rgba(15, 15, 15, 0.5);
  border: 1px solid var(--border);
  border-radius: 6px;
  color: var(--text);
  font-family: inherit;
  font-size: 0.95rem;
  transition: all 0.2s ease;
}

.settings-form input:focus {
  outline: none;
  border-color: var(--primary);
}


.table-container {
  overflow-x: auto;
  background-color: var(--bg-card);
  border-radius: 8px;
  border: 1px solid var(--border);
}

.data-table {
  width: 100%;
  border-collapse: collapse;
}

.data-table th,
.data-table td {
  padding: 1rem;
  text-align: left;
  border-bottom: 1px solid var(--border);
}

.data-table th {
  font-weight: 600;
  color: var(--primary);
  font-size: 0.95rem;
}

.data-table tr:last-child td {
  border-bottom: none;
}


.payment-section {
  max-width: 650px;
  margin: 0 auto;
}

.payment-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.payment-step {
  background-color: var(--bg-card);
  border-radius: 8px;
  padding: 16px;
  border: 1px solid var(--border);
}

.payment-step h3 {
  margin-top: 0;
  margin-bottom: 14px;
  font-size: 15px;
  font-weight: 500;
  color: var(--text);
}

.plan-options, .payment-methods {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.plan-option, .payment-method {
  display: flex;
  align-items: center;
  background-color: #222232;
  border-radius: 8px;
  padding: 12px;
  cursor: pointer;
  transition: all 0.2s ease;
  border: 1px solid transparent;
  position: relative;
}

.plan-option:hover, .payment-method:hover {
  background-color: #2a2a3a;
}

.plan-option.selected, .payment-method.selected {
  border-color: var(--primary);
  background-color: rgba(108, 92, 231, 0.1);
}

.plan-option input, .payment-method input {
  margin-right: 12px;
  accent-color: var(--primary);
}

.plan-name {
  flex-grow: 1;
  font-size: 14px;
  font-weight: 400;
  color: var(--text);
}

.plan-price {
  font-weight: 600;
  color: var(--primary);
  font-size: 14px;
}

.payment-name {
  font-weight: 500;
  font-size: 14px;
  margin-right: 10px;
  color: var(--text);
}

.payment-country {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: var(--text-muted);
  gap: 4px;
  margin-left: auto;
}

.payment-summary {
  background-color: var(--bg-card);
  border-radius: 8px;
  padding: 16px;
  border: 1px solid var(--border);
}

.order-details {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

.order-item {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  border-bottom: 1px solid var(--border);
  font-size: 14px;
  color: var(--text);
}

.order-item.total {
  font-weight: 600;
  color: var(--text);
  border-bottom: none;
  padding-top: 14px;
  margin-top: 4px;
  font-size: 15px;
}

.plan-option.selected::before, .payment-method.selected::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 3px;
  background: var(--primary);
  border-radius: 8px 0 0 8px;
}


.subscription-container {
  max-width: 600px;
  margin: 0 auto;
}

.subscription-card h3 {
  font-size: 16px;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 15px;
  color: var(--text);
  padding: 20px 20px 0;
}

.subscription-status {
  display: flex;
  justify-content: flex-end;
  margin: 0 20px 20px;
}

.subscription-details {
  border-top: 1px solid var(--border);
  border-bottom: 1px solid var(--border);
  padding: 15px 20px;
  margin-bottom: 15px;
}

.detail-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.detail-item:last-child {
  margin-bottom: 0;
}

.detail-label {
  color: var(--text-muted);
  font-size: 14px;
}

.detail-value {
  color: var(--text);
  font-weight: 500;
  font-size: 14px;
}

.subscription-actions {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0 20px 20px;
}


.dashboard-error, .dashboard-success {
  padding: 12px 16px;
  border-radius: 6px;
  margin-bottom: 20px;
  font-size: 14px;
}

.dashboard-error {
  background-color: rgba(255, 82, 82, 0.1);
  border: 1px solid rgba(255, 82, 82, 0.3);
  color: #ff5252;
}

.dashboard-success {
  background-color: rgba(46, 213, 115, 0.1);
  border: 1px solid rgba(46, 213, 115, 0.3);
  color: #2ed573;
}


@media (max-width: 1040px) {
  .dashboard-container {
    width: 95%;
  }
}

@media (max-width: 968px) {
  .dashboard-container {
    flex-direction: column;
    width: 95%;
    min-height: auto;
  }
  
  .dashboard-sidebar {
    width: 100%;
    border-right: none;
    border-bottom: 1px solid var(--border);
  }
  
  .navigation-menu {
    display: flex;
    flex-wrap: wrap;
    padding: 1rem;
  }
  
  .nav-item {
    width: auto;
    padding: 0.6rem 1rem;
    border-left: none;
    border-radius: 4px;
  }
  
  .nav-item.active {
    border-left: none;
    background-color: rgba(108, 92, 231, 0.2);
  }

  .content-section {
    min-height: 300px;
  }
}

@media (max-width: 576px) {
  .dashboard-wrapper {
    padding: 6rem 0 2rem;
  }
  
  .profile-grid,
  .settings-cards {
    grid-template-columns: 1fr;
  }
  
  .dashboard-content {
    padding: 1.5rem 1rem;
  }
}