.not-found-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 150px);
    padding: 40px 20px;
    text-align: center;
    position: relative;
    background-color: #0f0f0f;
    color: white;
  }
  
  .not-found-image-container {
    margin-bottom: 10px;
    max-width: 100%;
    animation: float 3s ease-in-out infinite;
  }
  
  .not-found-duck-image {
    width: 200px;
    height: auto;
    filter: drop-shadow(0 8px 16px rgba(108, 92, 231, 0.3));
  }
  
  @keyframes float {
    0% {
      transform: translateY(0px);
    }
    50% {
      transform: translateY(-15px);
    }
    100% {
      transform: translateY(0px);
    }
  }
  
  .not-found-code {
    font-size: 180px;
    margin: 0;
    color: #7b68ee;
    line-height: 1;
    font-weight: bold;
    margin-top: -20px;
  }
  
  .not-found-title {
    font-size: 28px;
    margin-top: 0;
    font-weight: 500;
    color: #fff;
  }
  
  .not-found-description {
    font-size: 16px;
    color: rgba(255, 255, 255, 0.7);
    max-width: 600px;
    margin: 25px auto;
    line-height: 1.5;
  }
  
  .not-found-actions {
    margin-top: 40px;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 60px;
  }
  
  .not-found-primary-button {
    padding: 12px 24px;
    background-color: #7b68ee;
    color: white;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 500;
    transition: background-color 0.2s, transform 0.1s;
  }
  
  .not-found-primary-button:hover {
    background-color: #6c5ce7;
    transform: translateY(-2px);
  }
  
  .not-found-secondary-button {
    padding: 12px 24px;
    background-color: transparent;
    color: white;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 4px;
    text-decoration: none;
    font-weight: 500;
    transition: border-color 0.2s, transform 0.1s;
  }
  
  .not-found-secondary-button:hover {
    border-color: rgba(255, 255, 255, 0.5);
    transform: translateY(-2px);
  }
  
  @media (max-width: 768px) {
    .not-found-duck-image {
      width: 150px;
    }
    
    .not-found-code {
      font-size: 140px;
    }
    
    .not-found-title {
      font-size: 24px;
    }
    
    .not-found-actions {
      flex-direction: column;
      width: 100%;
      max-width: 280px;
    }
  }
  
  .not-found-footer {
    margin-top: auto;
    width: 100%;
    background-color: #121212;
    padding: 20px 0;
    text-align: center;
    display: flex;
    justify-content: center;
  }
  
  .footer-content {
    display: flex;
    justify-content: center;
    gap: 30px;
  }
  
  .footer-link {
    color: rgba(255, 255, 255, 0.7);
    text-decoration: none;
    font-size: 14px;
    transition: color 0.2s;
  }
  
  .footer-link:hover {
    color: #7b68ee;
  }
  
  .footer-copyright {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
  }