.hero {
    position: relative;
    height: 75vh;
    min-height: 500px;
    display: flex;
    align-items: center;
    overflow: hidden;
    width: 100%;
  }
  
  .hero-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  
  .webp-bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center center;
    opacity: 0.6;
    filter: blur(30px);
  }
  
  .hero-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, rgba(10,10,10,0.9) 0%, rgba(10,10,10,0.8) 100%);
    z-index: 2;
  }
  
  .hero-content {
    position: relative;
    z-index: 3;
    width: 100%;
    max-width: 800px;
  }
  
  .features {
    background-color: var(--bg-section);
  }
  
  .section-header {
    text-align: center;
    margin-bottom: 4rem;
  }
  
  .section-title {
    margin-bottom: 1rem;
  }
  
  .section-description {
    color: var(--text-muted);
    max-width: 700px;
    margin: 0 auto;
  }
  
  .features-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
    gap: 2rem;
  }
  
  .feature-card {
    background-color: var(--bg-card);
    border-radius: 8px;
    padding: 2rem;
    border: 1px solid var(--border);
    transition: transform 0.2s ease, border-color 0.2s ease;
  }
  
  .feature-card:hover {
    transform: translateY(-3px);
    border-color: var(--primary);
  }
  
  .feature-title {
    margin-bottom: 1rem;
    color: var(--primary);
  }
  
  .feature-description {
    color: var(--text-muted);
  }
  
  .pricing {
    background-color: var(--bg-dark);
    padding: 4rem 0;
  }
  
  .pricing-container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 2rem;
  }
  
  .pricing-card {
    background-color: var(--bg-card);
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid var(--border);
    width: 300px;
    flex: 0 0 auto;
    margin: 0;
    display: flex;
    flex-direction: column;
  }
  
  .pricing-card:hover {
    transform: translateY(-5px);
    border-color: var(--primary);
    transition: transform 0.3s ease, border-color 0.3s ease;
  }
  
  .pricing-header {
    padding: 2rem;
    text-align: center;
    border-bottom: 1px solid var(--border);
    flex-grow: 1;
  }
  
  .pricing-price {
    font-size: 3rem;
    font-weight: 700;
    color: var(--primary);
    margin-bottom: 0.5rem;
  }
  
  .pricing-duration {
    color: var(--text-muted);
    font-size: 1rem;
  }
  
  .pricing-footer {
    padding: 1.5rem;
    text-align: center;
  }
  
  .btn-full {
    display: block;
    width: 100%;
    padding: 0.75rem 1.5rem;
    background-color: var(--primary);
    color: white;
    text-align: center;
    border-radius: 4px;
    text-decoration: none;
    font-weight: 600;
    transition: background-color 0.2s ease;
  }
  
  .btn-full:hover {
    background-color: var(--primary-dark, #4a3aff);
  }
  
  @media (max-width: 992px) {
    .pricing-container {
      flex-direction: column;
      align-items: center;
    }
  }