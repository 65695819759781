@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

:root {
    --primary: #5d3fd3;
    --secondary: #2c2c2c;
    --bg-dark: #0a0a0a;
    --bg-section: #111111;
    --bg-card: #161616;
    --bg-card-hover: #1a1a1a;
    --text: #f0f0f0;
    --text-muted: #a0a0a0;
    --text-light: #d0d0d0;
    --border: #222222;
    --navbar-bg: rgba(10, 10, 10, 0.95);
    --shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html, body {
    height: 100%;
    margin: 0;
    padding: 0;
}

body {
    font-family: 'Inter', sans-serif;
    background-color: var(--bg-dark);
    color: var(--text);
    font-size: 16px;
    line-height: 1.5;
    overflow-x: hidden;
    text-transform: lowercase;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: 700;
    line-height: 1.2;
}

h1 {
    font-size: clamp(2.5rem, 6vw, 4rem);
}

h2 {
    font-size: clamp(1.8rem, 4vw, 2.5rem);
}

h3 {
    font-size: clamp(1.2rem, 3vw, 1.8rem);
}

p {
    margin-bottom: 1rem;
}

main {
    flex: 1 0 auto;
}

.container {
    width: 100%;
    max-width: 1280px;
    margin: 0 auto;
    padding: 0 1.5rem;
}

section {
    padding: 6rem 0;
}

.navbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    padding: 1.2rem 0;
    background-color: var(--navbar-bg);
    z-index: 1000;
    border-bottom: 1px solid var(--border);
}

.navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.logo {
    color: var(--primary);
    font-size: 1.5rem;
    font-weight: 700;
    text-decoration: none;
    letter-spacing: -0.5px;
}

.nav-links {
    display: flex;
    gap: 2rem;
    align-items: center;
}

.nav-link {
    color: var(--text);
    text-decoration: none;
    font-size: 0.95rem;
    font-weight: 500;
    transition: color 0.2s ease;
}

.nav-link:hover, .nav-link.active {
    color: var(--primary);
}

.btn {
    display: inline-block;
    background-color: var(--primary);
    color: var(--text);
    padding: 0.75rem 1.5rem;
    border-radius: 4px;
    font-weight: 500;
    font-size: 0.95rem;
    text-decoration: none;
    transition: all 0.2s;
    border: none;
    cursor: pointer;
    text-transform: lowercase;
}

.btn:hover {
    filter: brightness(1.1);
    transform: translateY(-1px);
}

.btn-outline {
    background-color: transparent;
    border: 1px solid var(--primary);
}

.btn-outline:hover, .btn-outline.active {
    background-color: var(--primary);
}

.btn-full {
    width: 100%;
}

.footer {
    flex-shrink: 0;
    padding-bottom: 1.5rem;
}

.footer-content {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    margin: 0 auto;
    max-width: 300px;
    border-radius: 10px;
    background-color: rgba(30, 30, 35, 0.6);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
}

.footer-links {
    display: flex;
    gap: 1.5rem;
}

.footer-links a {
    color: var(--text-muted);
    font-size: 0.9rem;
    text-decoration: none;
    transition: color 0.2s ease;
}

.footer-links a:hover {
    color: var(--primary);
    text-decoration: underline;
}

.copyright {
    color: var(--text-muted);
    font-size: 0.9rem;
    text-align: center;
}

@media (max-width: 1280px) {
    .container {
        max-width: 1024px;
    }
    
    section {
        padding: 5rem 0;
    }
}

@media (max-width: 1024px) {
    .container {
        max-width: 768px;
    }
    
    h1 {
        font-size: clamp(2.2rem, 5vw, 3.5rem);
    }
    
    h2 {
        font-size: clamp(1.6rem, 3.5vw, 2.2rem);
    }
    
    section {
        padding: 4rem 0;
    }
}

@media (max-width: 768px) {
    .container {
        max-width: 100%;
        padding: 0 1rem;
    }
    
    .navbar {
        padding: 1rem 0;
    }
    
    .navbar-container {
        flex-direction: column;
        gap: 1rem;
    }
    
    .nav-links {
        width: 100%;
        justify-content: space-between;
        gap: 1rem;
    }
    
    .btn {
        padding: 0.6rem 1.2rem;
        font-size: 0.9rem;
    }
    
    section {
        padding: 3rem 0;
    }
    
    .footer-content {
        max-width: 90%;
        flex-direction: column;
        gap: 0.8rem;
    }
    
    .footer-links {
        flex-wrap: wrap;
        justify-content: center;
        gap: 1rem;
    }
}

@media (max-width: 576px) {
    body {
        font-size: 15px;
    }
    
    .navbar {
        padding: 0.8rem 0;
    }
    
    .logo {
        font-size: 1.3rem;
    }
    
    .nav-links {
        gap: 0.8rem;
        flex-wrap: wrap;
        justify-content: center;
    }
    
    .nav-link {
        font-size: 0.85rem;
    }
    
    .btn {
        padding: 0.5rem 1rem;
        font-size: 0.85rem;
    }
    
    h1 {
        font-size: clamp(2rem, 4.5vw, 3rem);
    }
    
    h2 {
        font-size: clamp(1.4rem, 3vw, 2rem);
    }
    
    h3 {
        font-size: clamp(1.1rem, 2.5vw, 1.6rem);
    }
    
    section {
        padding: 2.5rem 0;
    }
    
    .footer-content {
        padding: 0.8rem;
    }
    
    .copyright {
        font-size: 0.8rem;
    }
}

@media (max-width: 375px) {
    body {
        font-size: 14px;
    }
    
    .container {
        padding: 0 0.8rem;
    }
    
    .navbar {
        padding: 0.7rem 0;
    }
    
    .logo {
        font-size: 1.2rem;
    }
    
    .nav-links {
        gap: 0.6rem;
    }
    
    .nav-link {
        font-size: 0.8rem;
    }
    
    .btn {
        padding: 0.4rem 0.8rem;
        font-size: 0.8rem;
    }
    
    section {
        padding: 2rem 0;
    }
    
    .footer-content {
        padding: 0.7rem;
        max-width: 95%;
    }
    
    .footer-links {
        gap: 0.8rem;
    }
    
    .footer-links a {
        font-size: 0.8rem;
    }
    
    .copyright {
        font-size: 0.75rem;
    }
}